.layout-footer {
    height: var(--height-footer) !important;
    padding: 1.2rem 2rem;
    align-items: center;
    justify-content: space-between;
    background-color: var(--surface-a);
    a {
        color: var(--text-color);
        font-weight: 600;
    }
    img {
        vertical-align: sub;
        color: var(--text-color);
    }
    .layout-footer-right {
        a {
            i {
                color: var(--text-secondary-color);
                font-size: 1.5rem;
            }
        }
    }
}
