
.custom-button{
    color: #ffffff;
    background: #007bff;
    border: 1px solid #007bff;
    padding: 0.5rem 0.75rem;
    font-size: 1rem;
    transition: background-color 0.15s, border-color 0.15s, box-shadow 0.15s;
    border-radius: 4px;
    cursor:pointer;
}
.custom-button-outlined{
    background:none;
    color:var(--primary);
    border: 1px solid var(--primary);
    svg{
        margin-right: 0.5rem;
    }
}
.custom-button-outlined-secondary{
    color:var(--secondary);
    border: 1px solid var(--primary);

}
.custom-button-outlined-success{
    color:var(--success);
    border: 1px solid var(--success);

}
.custom-button-outlined-info{
    color:var(--info);
    border: 1px solid var(--info);

}
.custom-button-outlined-warning{
    color:var(--warning);
    border: 1px solid var(--warning);

}
.custom-button-outlined-help{
    color:var(--help);
    border: 1px solid var(--help);

}
.custom-button-outlined-danger{
    color:var(--danger);
    border: 1px solid var(--danger);

}

