@mixin dialog-maximized {
    .p-dialog-maximized {
        -webkit-transition: none !important;
        transition: none !important;
        -webkit-transform: none !important;
        transform: none !important;
        width: 100vw !important;
        max-height: 100%;
        height: 100%;
        max-width: 100vw !important;
        iframe {
            width: 100% !important;
            height: 100vh !important;
        }
    }
}

.p-dialog-default {
    position: relative;
    width: auto;
    margin: 0.5rem;
}
@media screen and (min-width: $xs) {
    .layout-content {
        padding-top: var(--height-header-footer) !important;
    }
    .p-dialog-xs {
        max-width: 300px !important;
    }
    @include dialog-maximized();
}
@media screen and (min-width: $sm) {
    .p-dialog-default {
        max-width: 500px !important;
        margin: 1.75rem auto;
    }
    .p-dialog-sm {
        max-width: 300px !important;
    }
    @include dialog-maximized();
}
// tablet
@media screen and (min-width: $md) {
    .p-dialog-md {
        max-width: 500px !important;
    }
    @include dialog-maximized();
}
// laptop
@media screen and (min-width: $lg) {
    .p-dialog-lg,
    .p-dialog-xl {
        max-width: 800px !important;
    }
    @include dialog-maximized();

    
}
@media screen and (min-width: $xl) {
    .p-dialog-xl {
        max-width: 1140px !important;
    }
    @include dialog-maximized();
}

// responsive table
@media screen and (max-width: $sm) {
    html {
        font-size: 13px;
    }
    .p-paginator {
        .p-dropdown,
        .p-inputwrapper,
        .p-inputwrapper-filled {
            margin-left: 0 !important;
            margin-bottom: 0.5rem !important;
        }
        button {
            margin-bottom: 0.5rem !important;
        }
    }
    .p-toolbar-group-left {
        width: 100% !important;
    }
    .p-toolbar-group-right {
        width: 100% !important;
    }
    .p-paginator {
        button {
            min-width: 2rem !important;
            height: 2rem !important;
        }
        .p-dropdown {
            height: 2rem !important;
        }
    }
    .p-toast {
        width: 20rem;
        right: 0 !important;
    }
    @mixin block-ellipsis($row) {
        display: -webkit-box;
        -webkit-line-clamp: $row;
        -webkit-box-orient: vertical;
        overflow: hidden;
    }
    .max-line-3 {
        @include block-ellipsis(5);
        span {
            @include block-ellipsis(5);
        }
    }
    .p-toast {
        width: 20rem;
        right: 0 !important;
    }
    .home {
        .p-card-body {
            .p-card-content {
                font-size: xx-large !important;
            }
        }
    }
    .layout-footer {
        padding: 0.5rem 2rem;
    }
}
