.custombar1 {
    max-height: 75vh;
    overflow-y: auto;
}
/* width */
::-webkit-scrollbar {
    width: 8px;
    height: 8px;

}

/* Track */
::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #c1c1c1;
    border-radius: 4px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #a8a8a8;
}
