.card {
    background: var(--surface-e);
    padding: 1.3rem;
    box-shadow: 0 2px 1px -1px rgba(0,0,0,.2), 0 1px 1px 0 rgba(0,0,0,.14), 0 1px 3px 0 rgba(0,0,0,.12);
    border-radius: 4px;
    margin-bottom: 2rem;
    .card-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
}
.country-item {
    display: flex;
    align-items: center;
    img.flag {
        width: 18px;
        margin-right: .5rem;
    }
}
.confirmation-content {
    display: flex;
    align-items: center;
    justify-content: center;
}
$font-weight: 600;
.content__info {
    .content__detail {
        &__border{
            border-bottom: 1px solid #dee2e6;
        }
        &__icon {
            svg {
                font-weight: bold;
                font-size: 1.2rem;
            }
        }
        &__title {
            font-weight: $font-weight;
        }
        &__value {
            &--bold {
                font-weight: $font-weight;
            }
        }
        &__step {
            &__icon {
                svg {
                    font-weight: bold;
                    font-size: 1.2rem;
                }
            }
            &__title {
                font-weight: $font-weight;
            }
            &__value {
                &--bold {
                    font-weight: $font-weight;
                }
            }
        }
    }
}
