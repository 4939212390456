.opacity-0 { opacity: 0 !important; }

.opacity-10 { opacity: .1 !important; }

.opacity-20 { opacity: .2 !important; }

.opacity-30 { opacity: .3 !important; }

.opacity-40 { opacity: .4 !important; }

.opacity-50 { opacity: .5 !important; }

.opacity-60 { opacity: .6 !important; }

.opacity-70 { opacity: .7 !important; }

.opacity-80 { opacity: .8 !important; }

.opacity-90 { opacity: .9 !important; }

.opacity-100 { opacity: 1 !important; }
