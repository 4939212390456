.layout-content {
    .content-section {
        min-height: var(--min-height-content);
        padding: 1.6rem !important;
    }
}
.p-menubar {
    .p-menubar-root-list > .p-menuitem > .p-menuitem-link:focus {
        outline: 0 none;
        outline-offset: 0;
        box-shadow: inset 0 0 0 0.15rem #ecece2;
    }
}

.p-chips {
    .p-chips-multiple-container {
        .p-chips-token {
            margin-top: 0.2rem;
        }
    }
}
.p-submenu-list:not(.menu-notify__ul) {
    width: auto !important;
    white-space: nowrap;
}

.p-filter-column {
    background-color: #ffffff !important;
}
// css nút dropdow
.p-paginator {
    padding: 0;
    .p-dropdown {
        margin-right: 0.5rem;
        div {
            span {
                color: var(--primary) !important;
            }
        }
    }
}
@mixin block-ellipsis($row) {
    display: -webkit-box;
    -webkit-line-clamp: $row;
    -webkit-box-orient: vertical;
    overflow: hidden;
}
.max-line-3 {
    @include block-ellipsis(3);
    span {
        @include block-ellipsis(3);
    }
}
.max-line-2 {
    @include block-ellipsis(2);
}
// table dài tự động xuống dòng
.table-word-break {
    .p-datatable-tbody {
        word-break: break-word !important;
    }
    .p-treetable-tbody {
        word-break: break-word !important;
    }
}
.break-word {
    word-break: break-word !important;
}
.p-toolbar {
    padding-top: 0.5rem;
    padding-bottom: 0rem;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    background: #fff;
    // border-left:none ;
    // border-right:none ;
    // border-radius: 0;
    background-color: var(--thead);
}

.p-datatable {
    .p-checkbox {
        .p-checkbox-box.p-highlight {
            border-color: var(--surface-c);
            // background: var(--tbody);
        }
    }
}

.icon-medium {
    font-size: 1.4em;
}
.icon-danh-so {
    font-size: 1.7em;
}
img.icon-medium {
    width: 20px;
    vertical-align: sub;
}

.p-tag-secondary {
    background-color: var(--secondary);
}
.pointer {
    cursor: pointer !important;
}

.textarea-custom {
    overflow-y: scroll !important;
    max-height: 6rem !important;
}

.p-tag.p-tag-xl {
    font-size: 1.5rem;
    min-width: 3rem;
    height: 3rem;
    line-height: 3rem;
}
.p-dialog-mask {
    z-index: 997 !important;
}
@mixin p-accordion-color($bg, $color) {
    background: $bg;
    border-color: $bg;
    color: $color;
}

// css tabel
.label-bold {
    font-weight: 500;
}

.stt-table {
    min-width: 3.5rem !important;
    width: 3.5rem !important;
    text-align: center !important;
    .p-column-header-content {
        justify-content: center;
    }
}
.action-table,
.action-tree-table {
    min-width: 8rem !important;
    width: 8rem !important;
    .p-column-header-content {
        justify-content: center;
    }
}

.header-center {
    text-align: center !important;
    .p-column-header-content {
        justify-content: center;
    }
}

.p-datatable {
    .p-datatable-thead tr th {
        background-color: var(--thead) !important;
    }
    .p-datatable-tbody > tr.p-highlight {
        background-color: var(--tbody) !important;
        color: var(--text-color);
    }
}

svg {
    vertical-align: sub;
}
.cursor-help {
    cursor: help;
}

.p-mb-n1 {
    margin-bottom: -0.5rem !important;
}
.p-pb-n1 {
    padding-bottom: -0.5rem !important;
}

.p-tree-selectable {
    max-height: 55vh;
    overflow-y: auto;
}
.select-option-custom {
    max-width: 15rem !important;
}

.p-disabled:not(button, .p-steps-item, .p-checkbox-box) {
    opacity: 1 !important;
    background-color: var(--surface-c) !important;
}
// bỏ margin scroll table
.p-datatable-scrollable-header-box,
.p-treetable-scrollable-header-box {
    margin-right: 0 !important;
}
.relative {
    position: relative;
}
.absolute {
    position: absolute;
}

.box-disabled {
    font-size: 1rem;
    color: #495057;
    border: 1px solid #ced4da;
    padding: 0.5rem 0.75rem;
    transition: background-color 0.15s, border-color 0.15s, box-shadow 0.15s;
    appearance: none;
    border-radius: 4px;
    overflow: auto;
    opacity: 1 !important;
    background-color: var(--surface-c);
    // cursor: no-drop !important;
}
.f-1-2r {
    font-size: 1.2rem;
}
.nowrap-text {
    white-space: nowrap;
}
.content-info-child {
    border-right: thin solid var(--surface-c);
    border-left: thin solid var(--surface-c);
    border-top: thin solid var(--surface-c);
    padding: 1rem;
    &:last-child {
        border-bottom: thin solid var(--surface-c);
    }
}
.overflow-hidden {
    overflow: hidden;
}
.overflow-visible {
    overflow: visible;
}
.font-size-18 {
    font-size: 18px;
}
.border-sign-view {
    border: 1px solid rgb(170, 170, 170);
}
.btn-handel {
    padding: 8px 16.5px;
}
.input-scroll > ul.p-inputtext {
    overflow-y: auto !important;
    max-height: 7rem;
}
.view-template-document {
    max-height: 100%;
    overflow-y: auto !important;
    text-overflow: hidden !important;
}
.fixed {
    position: fixed;
}
.dialog-no-scroll {
    .p-dialog-content {
        overflow-x: inherit !important;
    }
}
.no-border {
    border: none;
}
.overflow-y-auto {
    overflow-y: auto !important;
}
.overflow-x-auto {
    overflow-x: auto !important;
}

.inp-width {
    width: -webkit-fill-available;
}

.p-toast.p-toast-top-right,
.p-toast.p-toast-top-left {
    top: 100px;
}
.p-scrolltop {
    bottom: 44px;
    opacity: 0.5;
}
.PageProgress {
    z-index: 1003 !important;
}
